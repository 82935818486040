import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ConfigService } from '../../../helpers/config.service';
import { NavigationService } from '../../../services/navigation.service';
import { AuthAPIService } from '../../auth/services/auth-api.service';

@Component({
  selector: 'app-verify-changes',
  templateUrl: './verify-changes.component.html',
  styles: ``
})
export class VerifyChangesComponent implements OnInit {

  loading: boolean = false;
  Token: any
  constructor(
    // public dialogRef: MatDialogRef<VerifyChangesComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private _config: ConfigService,
    private _NavigationService: NavigationService,
    private _authApiSerivce: AuthAPIService,
    private _activatedRoute: ActivatedRoute) {
    this._activatedRoute.params.subscribe(params => {

      if (params['token']) {
        this.Token = params['token'];
        console.log(this.Token);
        this.CheckToken(this.Token);
      }
    });
  }
  ngOnInit(): void {

  }

  OnConfirm() {
    this.loading = true;
    this._NavigationService.VerifyChanges(this.Token).subscribe({
      next: (res) => {
        this.loading = false;
        // this.dialogRef.close();
        this._config.goTo("/");
        this.OpenSuccessMessage();
      },
      error: (error) => {
        this.loading = false;
        // this.dialogRef.close();
        // Swal.fire({
        //   title: this._config._translate.instant('swalMsg.Failed'),
        //   text: error.errors[0].massage,
        //   icon: 'error',
        //   confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
        // })
      },
    })
  }


  OpenSuccessMessage() {
    // this.dialogRef.close();
    this._config.SusseccMessage(this._config._translate.instant("login.Changes has been updated"))
  }


  CheckToken(token: string) {
    this._authApiSerivce.CheckToken(token).subscribe({
      next: (res: any) => {
        if (!res.data) {
          Swal.fire({
            title: this._config._translate.instant('swalMsg.Failed'),
            text: res.message,
            icon: 'error',
            confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
          });
          this._config.goTo("/");
        }
      },
      error: (error: any) => {
        this._config.goTo("/");
      }
    })
  }


}
