<div class="dialog pb-5">
    <!-- <div class="dialog_header">
        <a mat-dialog-close><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </a>
        {{"login.Verify Account Changes"|translate}}
    </div> -->
    <div class="dialog_content" mat-dialog-content>


        <p class="py-5 text-center">
            {{"login.Please confirm to perform the required update"|translate}}
        </p>

    </div>

    <div class="dialog_actions d-flex justify-content-center" mat-dialog-actions>
        <button mat-button class="btn-primary" (click)="OnConfirm()" [disabled]="loading"
            [ngClass]="{'loading': loading}">
            {{"general.confirm"|translate}}</button>
    </div>
</div>